<template>
  <div id="app">
    <TopBar v-if="showTopBar()"></TopBar>
    <router-view></router-view>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar'

export default {
  name: 'App',
  components: {
    TopBar
  },
  methods: {
    showTopBar() {
      return this.$route.path !== '/login'
    }
  }
}
</script>

<style>
html, body {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.app-container {
  padding-top: 20px;
  width: 80%;
  margin: 0 auto;
}

</style>
