<template>
  <div class="login-container">
    <el-form class="login-form" autocomplete="off" label-position="left">

      <div class="title-container">
        <h3 class="title">考试蚁直播后台</h3>
      </div>

      <el-form-item prop="username" class="input-div">
        <span class="svg-container">
          <i class="el-icon-user"/>
        </span>
        <el-input
            ref="username"
            v-model="account"
            placeholder="用户名"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="off"
        />
      </el-form-item>

      <el-form-item prop="password" class="input-div">
          <span class="svg-container">
            <i class="el-icon-lock"/>
          </span>
        <el-input
            :key="passwordType"
            ref="password"
            v-model="password"
            :type="passwordType"
            placeholder="密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
        />
      </el-form-item>

      <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;"
                 @click.native.prevent="handleLogin">登录
      </el-button>
    </el-form>
  </div>
</template>

<script>

import {post} from "@/utils/http";

export default {
  name: 'PageLogin',
  data() {
    return {
      account: '',
      password: '',
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      redirect: undefined,
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
        }
      },
      immediate: true
    }
  },
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    goIndex() {
      window.location.href = '/'
    },
    handleLogin() {
      if (this.account === '' || this.password === '') {
        return false
      }
      this.loading = true
      post('/account/login', {
        account: this.account,
        password: this.password
      }).then((response) => {
        this.loading = false
        if (response.code === 200) {
          this.$message.success('登录成功')
          setTimeout(() => {
            window.localStorage.setItem('token', response.data.token)
            window.localStorage.setItem('user', JSON.stringify(response.data.teacher))
            if (this.redirect !== undefined) {
              window.location.href = this.redirect
            } else {
              window.location.href = '/'
            }
          }, 1500)
        } else {
          this.$message.error(response.msg)
        }
      }).catch(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
.login-container {
  background-repeat: no-repeat;
  background-size: cover;
  -moz-background-size: cover;
  background-image: url("https://uuedu-platform.oss-cn-hangzhou.aliyuncs.com/assets/admin/20220928/v2-7b3942e8db4949799823933765a058c7_r.jpg");
  height: 100vh;
}

.login-form {
  position: absolute;
  width: 420px;
  max-width: 100%;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title-container {
  position: relative;
}

.title-container > .title {
  font-size: 26px;
  color: #454545;
  margin: 0px auto 40px auto;
  text-align: center;
  font-weight: bold;
}

.svg-container {
  padding-left: 15px;
  color: #454545;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
  font-size: 18px;
}

/deep/ .el-input__inner {
  border: none;
}

/deep/ .el-input {
  display: inline-block;
  height: 40px;
  width: 85%;
}

/deep/ input {
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 12px 5px 12px 15px;
  color: #454545;
  background-color: transparent !important;
  height: 47px;
  caret-color: #454545;
}

.input-div {
  border: 1px solid #889aa4;
  border-radius: 5px;
  color: #454545;
}

</style>
