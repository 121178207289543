import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login'
import ChangeInfo from '@/views/ChangeInfo'
import Index from '@/views/Index'
import CourseVideos from '@/views/CourseVideos'
import LiveRoom from '@/views/LiveRoom'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index
    },
    {
      path: '/course-videos/:id',
      name: 'CourseVideos',
      component: CourseVideos
    },
    {
      path: '/live-room/:id/:video_id',
      name: 'LiveRoom',
      component: LiveRoom
    },
    {
      path: '/change-info',
      name: 'ChangeInfo',
      component: ChangeInfo
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
  ]
})
