import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/base.css'

Vue.config.productionTip = false
Vue.use(Element)

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
