<template>
  <div class="app-container">

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>修改信息</el-breadcrumb-item>
    </el-breadcrumb>

    <el-form ref="form" label-width="80px" style="max-width: 400px; margin: 0 auto">
      <el-form-item label="姓名">
        <el-input v-model="name" maxlength="30" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="账号">
        <el-input v-model="account" disabled></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="password" type="password" maxlength="64" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input v-model="confirm_password" type="password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="float-right" type="primary" size="small" @click="save" :loading="loading">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

import {post} from "@/utils/http";

export default {
  name: 'UpdateAccountInfo',
  data() {
    return {
      loading: false,
      name: '',
      account: '',
      password: '',
      confirm_password: ''
    }
  },
  created() {
    this.user = window.localStorage.getItem('user')
    this.user = JSON.parse(this.user)
    this.name = this.user.teacher_name
    this.account = this.user.teacher_account
  },
  methods: {
    save() {
      if (this.name === '') {
        this.$message.error('请填写姓名')
        return false
      }
      if (this.password === '') {
        this.$message.error('请填写密码')
        return false
      }
      if (this.password !== this.confirm_password) {
        this.$message.error('密码填写不一致')
        return false
      }

      this.loading = true
      post('/account/change-info', {
        name: this.name,
        password: this.password
      }).then((response) => {
        this.loading = false
        if (response.code === 200) {
          this.$message.success('修改成功')
          window.localStorage.setItem('user', JSON.stringify(response.data.teacher))
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          this.$message.error(response.msg)
        }
      }).catch(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

.float-right {
  float: right;
}

</style>
