<template>
  <div class="app-container">

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">我的课程</el-breadcrumb-item>
    </el-breadcrumb>

    <el-table :data="courses" v-loading="loading" class="course-table" :show-header="false"
              empty-text="暂无课程，请联系考试蚁平台为您绑定课程">
      <el-table-column width="200">
        <template slot-scope="{row}">
          <el-image class="course-cover" :src="row.cover" fit="cover"></el-image>
        </template>
      </el-table-column>
      <el-table-column min-width="400">
        <template slot-scope="{row}">
          <p class="course-title">{{ row.name }}</p>
          <p class="course-desc" v-if="row.today_has_live_video === 0">今日无直播课</p>
          <p class="course-desc" v-else>今日 {{ getLiveVideoAt(row.today_live_videos) }} 有直播课</p>
        </template>
      </el-table-column>
      <el-table-column width="160">
        <template slot-scope="{row}">
          <el-link @click="showCourseInfo(row.id)" :underline="false">查看详情</el-link>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>

import {post} from "@/utils/http";

export default {
  name: 'PageIndex',
  data() {
    return {
      loading: false,
      courses: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      post('/course/course-list', {}).then((response) => {
        this.loading = false
        if (response.code === 200) {
          this.courses = response.data.list
        } else {
          this.$message.error(response.msg)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    showCourseInfo(id) {
      this.$router.push({
        path: '/course-videos/' + id,
      })
    },
    getLiveVideoAt(videos) {
      let at = []
      videos.forEach((val) => {
        at.push(this.formatTodayTime(val['live_start_at']))
      })

      return at.join('、')
    },
    formatTodayTime(time) {
      time = new Date(time)
      let minutes = time.getMinutes()
      let hours = time.getHours()
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (hours < 10) {
        hours = '0' + hours
      }
      return hours + ':' + minutes
    }
  }
}
</script>

<style scoped>

/deep/ .el-table__empty-text {
  margin-top: 100px;
}

.el-table::before {
  height: 0;
}

.course-table {
  width: 100%;
  min-height: 300px;
  margin-top: 20px;
}

.course-cover {
  width: 180px;
  height: 120px;
}

.course-title {
  margin-left: 20px;
  color: #303133;
  font-size: 1rem;
}

.course-desc {
  margin-left: 20px;
  color: #606266;
}

</style>
