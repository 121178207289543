<template>
  <div class="app-container" v-loading.fullscreen.lock="loading">

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item @click.native="go( '/')"><a>我的课程</a></el-breadcrumb-item>
      <el-breadcrumb-item @click.native="go( '/course-videos/' + course_id)"><a>课程详情</a></el-breadcrumb-item>
      <el-breadcrumb-item>直播详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="live-content">
      <div class="live-title-div">
        <el-row>
          <el-col :span="6">{{ video_name }}</el-col>
          <el-col :span="6" v-show="is_living">直播时长：{{ getLiveTime(live_time) }}</el-col>
          <el-col :span="4" v-show="is_living">在线人数：{{ living_count }}人</el-col>
        </el-row>
      </div>

      <div class="live-div">
        <el-row :gutter="24">
          <el-col :span="18">
            <div class="live-pusher" id="live-pusher">
              <div id="local_video" class="local-video"></div>
            </div>
          </el-col>
          <el-col :span="6" style="padding-left: 0">

            <div class="live-room-div" :style="{
              height: video_div_height + 'px'
            }">
              <el-row style="height: 30px">
                <el-col :span="12" style="font-size: 18px; font-weight: 500;">留言互动</el-col>
                <el-col :span="12" style="text-align: right">
                  <el-link :underline="false" style="margin-top: 2px" @click="setGroupProhibitionAlert">
                    {{ group_is_prohibition ? '取消全员禁言' : '全员禁言' }}
                  </el-link>
                </el-col>
              </el-row>
              <div id="message-list-div" class="message-div" @scroll="handleScroll">
                <p></p>
                <p v-for="(item, index) in message_list" class="message-p">
                  {{ item.name }}：{{ item.message }}
                </p>
                <div v-show="unread_message_count" class="unread-message-count-div" @click="scrollMessageToBottom">
                  {{ unread_message_count }}条新消息
                </div>
              </div>
              <div class="message-input-div">
                <el-input placeholder="点击输入" v-model="message" class="input-with-select"
                          @keyup.enter.native="sendMessage()" maxlength="50" show-word-limit>
                  <el-button @click="sendMessage()" slot="append" :disabled="!is_living">发送</el-button>
                </el-input>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="24" class="button-div">
          <el-col :span="3">
            <el-button @click="handleAudio" circle class="button">
              <img v-show="is_mute" src="../assets/images/mute_on.png" class="button-img"/>
              <img v-show="!is_mute" src="../assets/images/mute_off.png" class="button-img"/>
            </el-button>
            <p class="button-text-p">{{ is_mute ? '取消静音' : '静音' }}</p>
          </el-col>
          <el-col :span="3">
            <el-button @click="handleCamera" circle class="button">
              <img v-show="is_open_camera" src="../assets/images/camera_on.png" class="button-img"/>
              <img v-show="!is_open_camera" src="../assets/images/camera_off.png" class="button-img"/>
            </el-button>
            <p class="button-text-p">{{ is_open_camera ? '关闭摄像头' : '开启摄像头' }}</p>
          </el-col>
          <el-col :span="3">
            <el-button @click="handleScreen" circle class="button">
              <img src="../assets/images/screen.png" class="button-img"/>
            </el-button>
            <p class="button-text-p">{{ is_share_screen ? '关闭共享' : '共享屏幕' }}</p>
          </el-col>
          <el-col :span="9" style="text-align: right">
            <el-button v-show="!is_living" @click="startVideoLiveAlert">
              {{ getStartBtnText().text }}
            </el-button>
            <el-button v-show="is_living" @click="stopVideoLiveAlert">
              停止直播
            </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <remote-js src="https://video.sdk.qcloudecdn.com/web/TXLivePusher-2.1.0.min.js"
               @load-js-finish="loadLivePusher"></remote-js>
  </div>
</template>

<script>

import Vue from "vue";
import {post} from "@/utils/http";
import TencentCloudChat from '@tencentcloud/chat';

export default {
  name: 'CourseInfo',
  data() {
    return {
      loading: false,
      course_id: 0,
      video_id: 0,
      video_name: '',
      live_status: 0,
      live_time: 0,
      living_count: 0,
      living_count_rate: 1,
      live_countdown_seconds: 0,
      is_living: false,
      live_js_is_loaded: false,
      live_cache_data: null,
      message_list: [],
      message: '',
      is_mute: true,
      is_open_camera: false,
      is_share_screen: false,
      live_pusher: null,
      video_effect_manager: null,
      show_select_camera: false,
      push_url: '',
      stream_name: '',
      group_id: '',
      group_user_sign: '',
      group_owner_account: '',
      chat: null,
      chat_sdk_app_id: '',
      unread_message_count: 0,
      group_is_prohibition: false,
      need_set_group_prohibition: false,
      active_stop_live: false,
      live_statues: {
        STATUS_VIDEO_PLAY: 1,  // 可播放（试看或查看回放）
        STATUS_VIDEO_LIVING: 2, // 直播中
        STATUS_VIDEO_WAITING_LIVE: 3, // 等待直播
        STATUS_VIDEO_WAITING_PLAYBACK: 4, // 等待回放
      },
      system_message_type: {
        CHAR_ROOM_SYSTEM_MESSAGE_TYPE_DESTROY_GROUP: 1 // 解散群消息 等同于直播结束
      },
      video_div_height: 0,
      network_error: false
    }
  },
  created() {
    this.course_id = this.$route.params.id
    this.video_id = this.$route.params.video_id
    if (isNaN(this.course_id) || isNaN(this.video_id)) {
      this.$message.error('参数异常')
    } else {
      this.initRemoteJS()
      this.getVideoDetail()
    }
    this.changeMessageDivHeight()
  },
  methods: {
    go(path) {
      if (this.is_living) {
        this.$message.error('当前正在直播，请不要离开哦~')
        return false
      }
      this.$router.push({
        path: path
      })
    },
    handleScroll(e) {
      const {scrollTop, clientHeight, scrollHeight} = e.target
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        this.unread_message_count = 0
      }
    },
    stopVideoLiveAlert() {
      this.$confirm('是否结束直播？', '确认', {
        distinguishCancelAndClose: true,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '确认',
        confirmButtonClass: 'el-button--danger'
      }).then(() => {
        this.stopVideoLive()
      }).catch(() => {
      })
    },
    stopPush() {
      this.loading = false
      this.live_pusher.stopPush()
    },
    stopVideoLive() {
      this.loading = true
      post('/course/stop-video-live', {
        course_id: this.course_id,
        video_id: this.video_id,
      }).then((response) => {
        if (response.code === 200) {
          this.is_living = false
          this.active_stop_live = true
          setTimeout(() => {
            this.getVideoDetail()
            this.stopPush()
            this.$message.success('直播已结束')
          }, 3000)
        } else {
          this.loading = false
          this.$message.error(response.msg)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    setGroupProhibition(is_prohibition, notify = true) {
      const _ = this
      if (this.is_living) {
        this.chat.updateGroupProfile({
          groupID: this.group_id,
          muteAllMembers: is_prohibition,
        }).then((result) => {
          this.chat.setGroupAttributes({
            groupID: this.group_id,
            groupAttributes: {MuteAllMember: is_prohibition ? 'On' : 'Off'}
          }).then((result) => {
            _.checkGroupAttributes()
          }).catch((error) => {
            _.$message.error('直播间属性获取失败：' + error)
          })
          _.group_is_prohibition = is_prohibition
          if (notify) {
            _.$message.success(_.group_is_prohibition ? '全员禁言设置成功' : '全员禁言取消成功')
          }
        }).catch((error) => {
          _.$message.success(_.group_is_prohibition ? '全员禁言取消失败' : '全员禁言设置失败')
        })
      } else {
        _.need_set_group_prohibition = true
        _.group_is_prohibition = !_.group_is_prohibition
        _.$message.success(_.group_is_prohibition ? '全员禁言设置成功' : '全员禁言取消成功')
      }
    },
    setGroupProhibitionAlert() {
      this.$confirm(this.group_is_prohibition ? '是否取消全员禁言？' : '是否设置全员禁言？', '确认', {
        distinguishCancelAndClose: true,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '确认',
        confirmButtonClass: 'el-button--danger'
      }).then(() => {
        this.setGroupProhibition(!this.group_is_prohibition)
      }).catch(() => {
      })
    },
    sendMessage() {
      if (this.chat === null || !this.is_living || this.message === '') {
        return false
      }
      const message = this.chat.createTextMessage({
        to: this.group_id,
        conversationType: TencentCloudChat.TYPES.CONV_GROUP,
        payload: {
          text: this.message
        },
        needReadReceipt: true
      })
      const _ = this
      this.chat.sendMessage(message).then((result) => {
        switch (result.data.message.type) {
          case 'TIMTextElem':
            _.handleMessage(result.data.message.nick, result.data.message.payload.text, false)
            break
        }
        _.message = ''
      }).catch((result) => {
        this.$message.error('消息发送失败，请检查是否包含敏感词')
      })
    },
    getStartBtnText() {

      if (this.network_error) {
        return {
          text: '直播已断开，请检查网络后刷新此页面',
          status: 0
        }
      }

      switch (this.live_status) {
        case this.live_statues.STATUS_VIDEO_LIVING:
          if (this.live_cache_data !== null && this.live_cache_data.is_stopped === 1) {
            return {
              text: '直播已中断，继续直播',
              status: 1
            }
          }
          return {
            text: '正在直播中',
            status: 0
          }
        case this.live_statues.STATUS_VIDEO_PLAY:
        case this.live_statues.STATUS_VIDEO_WAITING_PLAYBACK:
          return {
            text: '直播已结束',
            status: 0
          }
      }

      if (this.live_countdown_seconds > 0) {
        return {
          text: this.getSecondTime(this.live_countdown_seconds) + ' 后开始直播',
          status: 0
        }
      } else if (this.live_countdown_seconds <= 0 && this.live_countdown_seconds > -3600) {
        return {
          text: '开始直播',
          status: 1
        }
      } else {
        return {
          text: '直播已超时',
          status: 0
        }
      }
    },
    getVideoDetail() {
      this.loading = true
      post('/course/video-detail', {
        course_id: this.course_id,
        video_id: this.video_id,
      }).then((response) => {
        this.loading = false
        if (response.code === 200) {
          this.video_name = response.data.title
          this.live_status = response.data.live_status
          this.live_countdown_seconds = response.data.live_countdown_seconds
          this.live_cache_data = response.data.cache_data
          if (parseInt(response.data.is_free) !== 0) {
            this.living_count_rate = 3
          }
          if (this.live_countdown_seconds > 0) {
            this.handleLiveCountDownSeconds()
          }
        } else {
          this.$message.error(response.msg)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleLiveCountDownSeconds() {
      this.live_countdown_seconds -= 1
      setTimeout(() => {
        if (this.live_countdown_seconds > 0) {
          this.handleLiveCountDownSeconds()
        }
      }, 1000)
    },
    handleAudio() {
      if (this.is_mute) {
        const device_manager = this.live_pusher.getDeviceManager()
        let has_audio = false
        device_manager.getDevicesList().then((data) => {
          data.forEach(function (device) {
            if (device.type === 'audio') {
              has_audio = true
            }
          })
          if (!has_audio) {
            this.$message.error('未检测到麦克风')
          } else {
            this.live_pusher.startMicrophone()
            this.is_mute = false
          }
        })
      } else {
        this.live_pusher.stopMicrophone()
        this.is_mute = true
      }
    },
    handleScreen() {
      if (!this.is_share_screen) {
        Promise.all([this.live_pusher.startScreenCapture(true)]).then((data) => {
          const stream_id = data.shift()
          this.video_effect_manager.setLayout({
            streamId: stream_id,
            x: 1920 / 2,
            y: 1080 / 2,
            width: 1920,
            height: 1080,
            zOrder: 1
          })
          this.is_share_screen = true
          if (this.is_open_camera) {
            this.stopCamera()
            this.handleCamera()
          }
          setTimeout(() => {
            this.changeMessageDivHeight()
          }, 100)
        })
      } else {
        if (this.is_living && !this.is_open_camera) {
          this.$message.error('屏幕共享和摄像头请至少打开一个')
          return false
        }
        this.live_pusher.stopScreenCapture()
        this.is_share_screen = false
        if (this.is_open_camera) {
          this.stopCamera()
          this.handleCamera()
        }
        this.changeMessageDivHeight()
      }
    },
    handleCamera() {
      if (!this.is_open_camera) {
        const device_manager = this.live_pusher.getDeviceManager()
        let has_camera = false
        device_manager.getDevicesList().then((data) => {
          data.forEach(function (device) {
            if (device.type === 'video') {
              has_camera = true
            }
          })
          if (!has_camera) {
            this.$message.error('未检测到摄像头')
          }
        })

        let width = 300
        let height = 180
        if (!this.is_share_screen) {
          width = 1920
          height = 1080
        }
        this.video_effect_manager = this.live_pusher.getVideoEffectManager()
        this.live_pusher.startCamera().then((data) => {
          this.video_effect_manager.setLayout({
            streamId: data,
            x: 1920 - width / 2,
            y: 1080 - height / 2,
            width: width,
            height: height,
            zOrder: 2
          })
          this.is_open_camera = true
          setTimeout(() => {
            this.changeMessageDivHeight()
          }, 100)
        })
      } else {
        if (this.is_living && !this.is_share_screen) {
          this.$message.error('屏幕共享和摄像头请至少打开一个')
          return false
        }
        this.stopCamera()
        this.changeMessageDivHeight()
      }
    },
    stopCamera() {
      this.live_pusher.stopCamera()
      this.is_open_camera = false
    },
    changeMessageDivHeight() {
      this.$nextTick(() => {
        this.video_div_height = document.getElementById('live-pusher').offsetHeight - 74
      })
    },
    loadLivePusher() {
      this.live_js_is_loaded = true
      this.live_pusher = new TXLivePusher()
      this.live_pusher.setRenderView('local_video')
      this.live_pusher.setVideoQuality('1080p')
      this.live_pusher.setAudioQuality('standard')
      this.live_pusher.setProperty('setVideoFPS', 25)

      this.video_effect_manager = this.live_pusher.getVideoEffectManager();
      this.video_effect_manager.enableMixing(true)
      this.video_effect_manager.setMixingConfig({
        videoWidth: 1920,
        videoHeight: 1080,
        videoFramerate: 25
      })
    },
    startVideoLiveAlert() {
      const check = this.checkLiveStatus()
      if (!check.status) {
        if (check.message !== '') {
          this.$message.error(check.message)
        }
        return false
      }
      this.$confirm('是否开始直播？', '确认', {
        distinguishCancelAndClose: true,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '确认',
        confirmButtonClass: 'el-button--primary'
      }).then(() => {
        this.startVideoLive()
      }).catch(() => {
      })
    },
    startVideoLive() {
      const check = this.checkLiveStatus()
      if (!check.status) {
        if (check.message !== '') {
          this.$message.error(check.message)
        }
        return false
      }
      this.loading = true
      post('/course/get-video-push-url', {
        course_id: this.course_id,
        video_id: this.video_id,
      }).then((response) => {
        this.loading = false
        if (response.code === 200) {
          this.push_url = response.data.push_url
          this.stream_name = response.data.stream_name
          this.startPushVideo()
        } else {
          this.$message.error(response.msg)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    checkLiveStatus() {
      const result = {
        status: false,
        message: ''
      }
      if (!this.live_js_is_loaded) {
        return result
      }
      const status = this.getStartBtnText()
      if (status.status !== 1) {
        return result
      }
      if (this.is_living) {
        return result
      }
      if (!this.is_open_camera && !this.is_share_screen) {
        result.message = '请打开屏幕共享或摄像头之后再开始直播'
        return result
      }

      result.status = true
      return result
    },
    scrollMessageToBottom() {
      const div = document.getElementById('message-list-div')
      div.scrollTop = div.scrollHeight
      this.unread_message_count = 0
    },
    handleMessage(name, message, owner = false) {
      if (this.message_list.length > 1000) {
        delete this.message_list[0]
      }
      const div = document.getElementById('message-list-div')
      const scroll = div.scrollTop + div.offsetHeight >= div.scrollHeight - 1
      this.message_list.push({
        name: name,
        message: message
      })
      if (scroll || owner) {
        this.$nextTick(() => {
          div.scrollTop = div.scrollHeight
        })
      } else {
        this.unread_message_count++
      }
    },
    startLiveChatRoom() {
      const _ = this
      this.chat = TencentCloudChat.create({
        SDKAppID: this.chat_sdk_app_id
      })
      // todo
      this.chat.setLogLevel(4)
      this.chat.login({userID: this.group_owner_account, userSig: this.group_user_sign}).then((result) => {
        this.chat.joinGroup({groupID: this.group_id}).then((result) => {
          this.checkGroupAttributes()
        })
        this.getGroupOnlineMemberCount()
      }).catch(() => {
        this.$message.error('直播间加入失败')
      })
      this.chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, function (event) {
        event.data.forEach((val) => {
          // console.log('chat 收到消息 start-----------------------')
          // console.log(val.type)
          // console.log(val)
          // console.log('chat 收到消息 stop-----------------------')
          switch (val.type) {
            case 'TIMGroupSystemNoticeElem':
              _.handleSystemMessage(val.payload.userDefinedField)
              break
            case 'TIMTextElem':
              _.handleMessage(val.nick, val.payload.text)
              break
          }
        })
      })
    },
    handleSystemMessage(message) {
      if (message === undefined) {
        return false
      }
      try {
        message = JSON.parse(message)
      } catch (e) {
        this.$message.error('系统消息处理失败：' + message)
        return false
      }
      switch (message.type) {
        case this.system_message_type.CHAR_ROOM_SYSTEM_MESSAGE_TYPE_DESTROY_GROUP:
          this.is_living = false
          setTimeout(() => {
            if (!this.active_stop_live) {
              this.getVideoDetail()
              this.stopPush()
              this.$alert('直播已被中断', '错误', {
                showConfirmButton: true,
                confirmButtonText: '我知道了',
                confirmButtonClass: 'el-button--danger'
              }).then(() => {
              }).catch(() => {
              })
            }
          }, 1000)
          break
      }
    },
    checkGroupAttributes() {
      if (this.need_set_group_prohibition) {
        this.chat.getGroupAttributes({
          groupID: this.group_id,
          keyList: []
        }).then((result) => {
          this.setGroupProhibition(this.group_is_prohibition, false)
          this.need_set_group_prohibition = false
        }).catch((error) => {
          this.$message.error('直播间属性获取失败：' + error)
        })
      } else {
        this.chat.getGroupAttributes({
          groupID: this.group_id,
          keyList: []
        }).then((result) => {
          if (result.data.groupAttributes.MuteAllMember === 'On') {
            this.group_is_prohibition = true
          } else {
            this.group_is_prohibition = false
          }
        }).catch((error) => {
          this.$message.error('直播间属性获取失败：' + error)
        })
      }
    },
    getGroupOnlineMemberCount() {
      const _ = this
      this.chat.getGroupOnlineMemberCount(this.group_id).then((result) => {
        if (result.code === 0) {
          _.living_count = result.data.memberCount * _.living_count_rate
        }
      })
      setTimeout(() => {
        if (_.is_living) {
          this.getGroupOnlineMemberCount()
        }
      }, 5000)
    },
    startPushVideo() {
      this.loading = true
      this.live_pusher.startPush(this.push_url).then(async () => {
        post('/course/start-video-live', {
          course_id: this.course_id,
          video_id: this.video_id,
        }).then((response) => {
          this.loading = false
          if (response.code === 200) {
            this.group_id = response.data.group_id
            this.group_user_sign = response.data.group_user_sign
            this.group_owner_account = response.data.group_owner_account
            this.chat_sdk_app_id = response.data.chat_sdk_app_id
            this.live_time = response.data.live_time
            this.is_living = true
            this.$message.success('直播已开始')
            this.countLiveTime()
            this.startLiveChatRoom()
          } else {
            this.$message.error(response.msg)
          }
        }).catch(() => {
          this.loading = false
        })

        this.live_pusher.videoView.muted = true

        const _ = this
        this.live_pusher.setObserver({
          onPushStatusUpdate: function (status, msg) {
            switch (status) {
              case 0: // 'disconnected'
                if (!_.active_stop_live) {
                  _.network_error = true
                  _.is_living = false
                  _.$alert('直播已断开，请检查网络后刷新此页面', '错误', {
                    showConfirmButton: true,
                    confirmButtonText: '我知道了',
                    confirmButtonClass: 'el-button--danger'
                  }).then(() => {
                  }).catch(() => {
                  })
                }
                break
            }
          },
        });
      })
    },
    countLiveTime() {
      this.live_time += 1
      setTimeout(() => {
        if (this.is_living) {
          this.countLiveTime()
        }
      }, 1000)
    },
    getLiveTime() {
      if (this.live_time >= 0) {
        let minute = parseInt(this.live_time / 60)
        if (minute < 10) {
          minute = '0' + minute
        }
        let left = this.live_time % 60
        if (left < 10) {
          left = '0' + left
        }
        return minute + ':' + left
      } else {
        return '00:00'
      }
    },
    getSecondTime(time) {
      if (time >= 0) {
        let minute = parseInt(time / 60)
        if (minute < 10) {
          minute = '0' + minute
        }
        let left = time % 60
        if (left < 10) {
          left = '0' + left
        }
        return minute + ':' + left
      } else {
        return '00:00'
      }
    },
    initRemoteJS() {
      Vue.component('remote-js', {
        render(createElement) {
          const self = this;
          return createElement('script', {
            attrs: {type: 'text/javascript', src: this.src},
            on: {
              load: function () {
                self.$emit('load-js-finish')
              }
            }
          })
        },
        props: {
          src: {type: String, required: true}
        }
      })
    },
  },
  watch: {}
}
</script>

<style scoped>

.live-content {
  margin-top: 20px;
}

.live-div {
  min-height: calc(100vh - 200px);
  background-color: #f5f7fa;
  margin-top: 10px;
  padding: 10px;
}

.live-title-div {
  text-align: left;
  color: #606266;
  font-size: 14px;
}

.live-pusher {
  min-height: calc(100vh - 290px);
  background-color: #303133;
  display: flex;
  align-items: center;
  overflow-y: auto;
}

.live-room-div {
  text-align: left;
  height: calc(100vh - 365px);
}

.message-div {
  height: 100%;
  overflow-y: auto;
}

.message-input-div {
  height: 40px;
  margin-top: 4px;
}

.message-p {
  background-color: #00000010;
  border-radius: 15px;
  padding: 8px 14px;
  font-size: 14px;
  margin-right: 2px;
  word-wrap: break-word;
}

::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #DCDFE6;
  border-radius: 5px;
}

.button-div {
  margin-top: 20px;
  height: 100px;
}

.button-text-p {
  margin-top: 6px;
  font-size: 12px;
}

.local-video {
  width: 100%;
}

.unread-message-count-div {
  position: absolute;
  z-index: 10;
  bottom: 50px;
  right: 20px;
  background-color: #00000090;
  color: #ffffff;
  padding: 6px 16px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
}

.button {
  height: 50px;
  width: 50px;
}

.button-img {
  width: 24px;
  height: 24px;
}

</style>
