import axios from 'axios'
import QS from 'qs'

import dev from '../../config/dev.env.js'
import pro from '../../config/pro.env.js'

const NODE_ENV = process.env.NODE_ENV;
if (NODE_ENV === 'production') {
  axios.defaults.baseURL = pro.API_ROOT;
} else {
  axios.defaults.baseURL = dev.API_ROOT;
}

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
    }).then(res => {
      console.log(res)
    }).catch(err => {
      reject(err.data)
    })
  });
}

export function post(url, params) {

  let headers = {
  }
  let user_token = window.localStorage.getItem('token')
  if (user_token !== null) {
    headers['Authorization'] = user_token
  }

  return new Promise((resolve, reject) => {
    axios.post(url, QS.stringify(params), {
      headers: headers
    }).then(res => {
      if (res.data.code === 4440) {
        setTimeout(() => {

          window.localStorage.setItem('token', null)
          window.localStorage.setItem('user', null)

          window.location.href = '/#/login'
        }, 1000)
      } else {
        resolve(res.data)
      }
    }).catch(err => {
      reject(err.data)
    })
  })
}