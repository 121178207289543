<template>
  <div class="app-container">

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">我的课程</el-breadcrumb-item>
      <el-breadcrumb-item>课程详情</el-breadcrumb-item>
    </el-breadcrumb>

    <el-tabs v-model="active_tab" style="margin-top: 24px" @tab-click="changeTab">
      <el-tab-pane label="直播课" name="live_course_tab">
        <div v-show="!loading && live_videos.length === 0">暂无视频</div>
        <div class="course-table">
          <el-row class="video-div" v-for="(item, index) in live_videos" v-bind:key="'live_video_' + index">
            <el-col :span="12">
              <p class="course-title">{{ item.title }}</p>
              <p class="course-desc">
                直播时间：{{ item.live_start_at !== null && item.live_start_at !== '' ? item.live_start_at : '未配置' }}</p>
            </el-col>
            <el-col :span="12">
              <p class="status-p">
                <span v-if="item.live_status !== 3">
                  <el-link :underline="false" @click="showMessageDialog(item.id)">查看消息</el-link>&nbsp;&nbsp;
                </span>
                <span v-if="item.live_status === 1">
                  <span class="status-span">已结束</span>&nbsp;&nbsp;<el-link @click="showVideoPlayer(item.tx_id, item.url, item.sign_data)" :underline="false">查看回放</el-link>
                </span>
                <span v-else-if="item.live_status === 2">
                  <span class="status-span">直播中</span>
                  &nbsp;&nbsp;
                  <el-link :underline="false" @click="liveRoom(item.id)">进入直播</el-link>
                </span>
                <span v-else-if="item.live_status === 3">
                  <span v-if="item.live_countdown_seconds <= 180">
                    <span class="status-span">直播倒计时 {{ getCountdown(item.live_countdown_seconds) }}</span>
                  </span>
                  <span class="status-span" v-else>待开始</span>
                  &nbsp;&nbsp;
                  <el-link :underline="false" @click="liveRoom(item.id)">进入直播</el-link>
                </span>
                <span v-else>
                  <span class="status-span">已结束&nbsp;&nbsp;等待回放</span>
                </span>
              </p>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="答疑课" name="question_course_tab">
        <div v-show="!loading && question_videos.length === 0">暂无视频</div>
        <div class="course-table">
          <el-row class="video-div" v-for="(item, index) in question_videos" v-bind:key="'live_video_' + index">
            <el-col :span="12">
              <p class="course-title">{{ item.title }}</p>
              <p class="course-desc">
                直播时间：{{ item.live_start_at !== null && item.live_start_at !== '' ? item.live_start_at : '未配置' }}</p>
            </el-col>
            <el-col :span="12">
              <p class="status-p">
                <span v-if="item.live_status !== 3">
                  <el-link :underline="false" @click="showMessageDialog(item.id)">查看消息</el-link>&nbsp;&nbsp;
                </span>
                <span v-if="item.live_status === 1">
                  <span class="status-span">已结束</span>&nbsp;&nbsp;<el-link @click="showVideoPlayer(item.tx_id, item.url, item.sign_data)" :underline="false">查看回放</el-link>
                </span>
                <span v-else-if="item.live_status === 2">
                  <span class="status-span">直播中</span>
                  &nbsp;&nbsp;
                  <el-link :underline="false" @click="liveRoom(item.id)">进入直播</el-link>
                </span>
                <span v-else-if="item.live_status === 3">
                  <span v-if="item.live_countdown_seconds <= 180">
                    <span class="status-span">直播倒计时 {{ getCountdown(item.live_countdown_seconds) }}</span>
                  </span>
                  <span class="status-span" v-else>待开始</span>
                  &nbsp;&nbsp;
                  <el-link :underline="false" @click="liveRoom(item.id)">进入直播</el-link>
                </span>
                <span v-else>
                  <span class="status-span">已结束&nbsp;&nbsp;等待回放</span>
                </span>
              </p>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-drawer
        title="查看回放"
        size="70%"
        :visible.sync="show_player"
        style="text-align: center"
        @close="closeVideo()"
        direction="rtl">

      <video id="player-container-id" style="width: 100%" preload="auto" playsinline webkit-playsinline></video>

    </el-drawer>

    <el-drawer
        title="查看消息"
        size="70%"
        :visible.sync="show_message"
        direction="rtl">

      <div style="padding: 10px; text-align: right" v-if="messages.data !== undefined">
        <el-table :data="messages.data" border style="width: 100%;">
          <el-table-column label="内容" header-align="center" align="center">
            <template slot-scope="scope">{{ scope.row.content }}</template>
          </el-table-column>
          <el-table-column label="发送时间" header-align="center" align="center">
            <template slot-scope="scope">{{ scope.row.created_at }}</template>
          </el-table-column>
        </el-table>

        <el-pagination
            style="margin-top: 10px;"
            :hide-on-single-page="true"
            class="pagination"
            :page-size="limit"
            @current-change="showMessage"
            background
            layout="prev, pager, next"
            :current-page="messages.current_page"
            :total="messages.total">
        </el-pagination>
      </div>

    </el-drawer>

  </div>
</template>

<script>

import {post} from "@/utils/http";
import TCPlayer from 'tcplayer.js';
import 'tcplayer.js/dist/tcplayer.min.css';

export default {
  name: 'CourseInfo',
  data() {
    return {
      loading: false,
      course_id: 0,
      live_videos: [],
      question_videos: [],
      courses: [],
      active_tab: 'live_course_tab',
      show_player: false,
      tencent_video_app_id: '',
      player: null,
      loaded_live_videos: true,
      loaded_question_videos: false,
      show_message: false,
      message_video_id: 0,
      limit: 15,
      messages: []
    }
  },
  created() {
    this.course_id = this.$route.params.id
    if (isNaN(this.course_id)) {
      this.$message.error('参数异常')
    } else {
      this.getData(1)
      this.changeCountdown()
    }
  },
  methods: {
    showMessageDialog(video_id) {
      this.message_video_id = video_id
      this.showMessage()
      this.show_message = true
    },
    showMessage(page) {
      if (page) {
        this.page = page
      } else {
        this.page = 1
      }
      this.loading = true
      post('/course/course-video-message', {
        course_id: this.course_id,
        video_id: this.message_video_id,
        page: this.page
      }).then((response) => {
        this.loading = false
        if (response.code === 200) {
          this.messages = response.data.list
        } else {
          this.$message.error(response.msg)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    liveRoom(video_id) {
      this.$router.push({
        path: '/live-room/' + this.course_id + '/' + video_id,
      })
    },
    changeTab(tab) {
      switch (tab.name) {
        case 'live_course_tab':
          break;
        case 'question_course_tab':
          if (!this.loaded_question_videos) {
            this.getData(2)
            this.loaded_question_videos = true
          }
          break;
      }

    },
    closeVideo() {
      this.player.pause()
    },
    getCountdown(second) {
      if (second >= 0) {
        let minute = parseInt(second / 60)
        if (minute < 10) {
          minute = '0' + minute
        }
        let left = second % 60
        if (left < 10) {
          left = '0' + left
        }
        return minute + ':' + left
      } else {
        return '00:00'
      }
    },
    showVideoPlayer(tx_id, url, sign_data) {
      this.show_player = true
      let video_url = url + '?t=' + sign_data.timeout + '&exper=' + sign_data.exper + '&us=' + sign_data.us + '&sign=' + sign_data.web_sign
      this.$nextTick(() => {
        if (this.player === null) {
          this.player = TCPlayer('player-container-id', {
            licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1312408808_1/v_cube.license'
          })
          this.player.src(video_url)
        } else {
          this.player.src(video_url)
        }
      })
    },
    getData(type) {
      this.loading = true
      post('/course/course-videos', {
        id: this.course_id,
        type: type
      }).then((response) => {
        this.loading = false
        if (response.code === 200) {
          if (type === 1) {
            this.live_videos = response.data.list
          } else {
            this.question_videos = response.data.list
          }
          this.tencent_video_app_id = response.data.tencent_video_app_id
        } else {
          this.$message.error(response.msg)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    changeCountdown() {
      this.live_videos.forEach((val) => {
        val.live_countdown_seconds -= 1
      })
      this.question_videos.forEach((val) => {
        val.live_countdown_seconds -= 1
      })
      setTimeout(() => {
        this.changeCountdown()
      }, 1000)
    },
    getLiveVideoAt(videos) {
      let at = []
      videos.forEach((val) => {
        at.push(this.formatTodayTime(val['live_start_at']))
      })

      return at.join('、')
    },
    formatTodayTime(time) {
      time = new Date(time)
      let minutes = time.getMinutes()
      let hours = time.getHours()
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      if (hours < 10) {
        hours = '0' + hours
      }
      return hours + ':' + minutes
    }
  }
}
</script>

<style scoped>

/deep/ .el-table__empty-text {
  margin-top: 100px;
}

.el-table::before {
  height: 0;
}

/deep/ .el-tabs__header {
  width: 124px;
}

.course-table {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
}

.video-div {
  flex: 1;
  min-width: calc((100% - 10px) / 2);
  max-width: 50%;
  text-align: left;
  padding: 10px 20px 10px 20px;
}

.video-div:hover {
  background-color: #f5f7fa;
}

.course-title {
  margin-left: 20px;
  color: #303133;
  font-size: 1rem;
}

.course-desc {
  margin-left: 20px;
  color: #606266;
  font-size: .8rem;
}

.status-p {
  height: 100%;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}

.status-span {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  text-decoration: none;
  outline: 0;
  padding: 0;
  font-weight: 500;
}

</style>
