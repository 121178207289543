<template>
  <div id="top-bar" class="top-bar">
    <el-row style="width: 80%; margin: 0 auto">
      <el-col :span="20" class="menu-item">考试蚁直播后台</el-col>
      <el-col :span="4" class="right-menu-item">
        <el-dropdown>
          <span class="el-dropdown-link name">
            {{ $root.user.teacher_name }} <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="changeInfo()">修改信息</el-dropdown-item>
            <el-dropdown-item @click.native="logout()">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "TopBar",
  data() {
    return {
      user: null
    }
  },
  created() {
    if (window.localStorage.getItem('user') === null || window.localStorage.getItem('token') === null) {
      window.location.href = '/#/login'
      return false
    } else {
      this.$root.user = JSON.parse(window.localStorage.getItem('user'))
    }
  },
  methods: {
    changeInfo() {
      this.$router.push({
        path: '/change-info'
      })
    },
    logout() {
      this.$confirm('是否退出登录？', '确认', {
        distinguishCancelAndClose: true,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '确认',
        confirmButtonClass: 'el-button--danger'
      }).then(() => {
        window.localStorage.setItem('token', null)
        window.localStorage.setItem('user', null)

        window.location.href = '/#/login'
      }).catch(() => {
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.name {
  font-size: 16px;
  color: #ffffff;
}

.top-bar {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #409EFF;
  color: #ffffff;
}

.right-menu-item {
  text-align: right;
  padding-right: 20px;
  cursor: pointer;
  padding-top: 4px;
}

.menu-item {
  font-size: 22px;
  text-align: left;
}

</style>
